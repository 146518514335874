import BigPageLink from "components/BigPageLink";
import Footer from "components/Footer";
import PageHeading from "components/PageHeading";
import CasesBlock from "components/work/CasesBlock";
import ClientsBlock from "components/work/ClientsBlock";
import WorkFilter from "components/work/WorkFilter";
import { graphql } from "gatsby";
import { HelmetDatoCms } from "gatsby-source-datocms";
import React from "react";
import { withTheme } from "styled-components";
import BaseTemplate from "templates/base";
import arrayFlatten from "utils/arrayFlatten";

const renderBlock = (block, theme, state) => {
  const apiKey = block && block.model ? block.model.apiKey : null;
  let Cmp = null;
  let extras = {};

  switch (apiKey) {
    case "cases_block":
      Cmp = CasesBlock;
      extras = { cases: state.cases, filter: state.filter };
      break;
    case "clients_block":
      Cmp = ClientsBlock;
      extras = { showFilters: true };
      break;
    case "big_page_link":
      Cmp = BigPageLink;
      extras = { big: true };
      break;
    default:
      break;
  }
  if (Cmp) {
    return <Cmp theme={theme} key={block.id} {...block} {...extras} />;
  }

  return null;
};

class WorkTemplate extends BaseTemplate {
  getFilteredCases(filter = null) {
    if (!filter || filter === "All") {
      return this.cases;
    }
    return this.cases.filter((c) =>
      c.typeOfWork.map((t) => t.name).includes(filter)
    );
  }

  constructor(props) {
    super(props);
    this.cases = props.data.page.body.filter(
      (block) => block.model.apiKey === "cases_block"
    )[0].cases;
    this.workTypes = ["All"].concat(
      arrayFlatten(
        this.cases.map((c) => c.typeOfWork.map((t) => t.name))
      ).filter((name, idx, self) => self.indexOf(name) === idx)
    );

    this.workTypes = this.workTypes.map((wt) => {
      return {
        value: wt,
        count:
          wt === "All" ?
            this.cases.length
          : this.cases.filter((c) =>
              c.typeOfWork.map((t) => t.name).includes(wt)
            ).length,
      };
    });

    this.state = { filter: "All", cases: this.getFilteredCases() };
  }

  onFilterChange = (filter) => {
    this.setState({ filter, cases: this.getFilteredCases(filter) });
  };

  render() {
    const { ready } = this.state;
    const { data, theme } = this.props;
    const { page } = data;
    let { heading, seoMetaTags, showCasesFilter, body } = page;

    return (
      <React.Fragment>
        <HelmetDatoCms seo={seoMetaTags} />
        <PageHeading
          ready={ready}
          theme={theme}
          heading={heading}
          pt={[150, 150, 80]}
          headingProps={{ pt: `2vh` }}
          pb={[60, 60, 60, 60, 125]}
          extra={
            showCasesFilter ?
              <WorkFilter
                theme={theme}
                defaultValue={this.workTypes[0]}
                options={this.workTypes}
                onChange={this.onFilterChange}
              />
            : null
          }
        />
        {body && body.map((block) => renderBlock(block, theme, this.state))}
        <Footer theme={theme} globals={this.props.pageContext.globals} />
      </React.Fragment>
    );
  }
}

export const query = graphql`
  query {
    page: datoCmsWorkPage {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      heading
      showCasesFilter
      body {
        ... on DatoCmsCasesBlock {
          id
          model {
            apiKey
          }
          cases {
            id
            model {
              apiKey
            }
            title
            client {
              id
              name
            }
            tagline
            typeOfWork {
              name
            }
            thumbnail {
              url
              alt
              fluid(
                maxWidth: 671
                maxHeight: 448
                imgixParams: {
                  auto: "compress,format"
                  fit: "crop"
                  w: "671"
                  h: "448"
                  fm: "jpg"
                  q: 75
                }
              ) {
                ...GatsbyDatoCmsFluid_noBase64
              }
            }
          }
        }
        ... on DatoCmsClientsBlock {
          id
          model {
            apiKey
          }
          heading
          clients {
            name
            typeOfWork {
              name
            }
            industry {
              name
            }
            logo {
              url
              alt
              fluid(
                maxWidth: 324
                imgixParams: { auto: "compress,format", fm: "jpg", q: 75 }
              ) {
                ...GatsbyDatoCmsFluid_noBase64
              }
            }
          }
        }
        ... on DatoCmsBigPageLink {
          id
          model {
            apiKey
          }
          paragraphNode {
            childMarkdownRemark {
              html
            }
          }
          linkLabel
          link
        }
      }
    }
  }
`;

export default withTheme(WorkTemplate);
